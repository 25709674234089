export default [
  {
    id: '1',
    title: 'Title 1',
    desc: 'Description 1',
    img: 'https://via.placeholder.com/150',
    link: 'https://example.com/link1',
  },
  {
    id: '2',
    title: 'Title 2',
    desc: 'Description 2',
    img: 'https://via.placeholder.com/150',
    link: 'https://example.com/link2',
  },
  {
    id: '3',
    title: 'Title 3',
    desc: 'Description 3',
    img: 'https://via.placeholder.com/150',
    link: 'https://example.com/link3',
  },
  {
    id: '4',
    title: 'Title 4',
    desc: 'Description 4',
    img: 'https://via.placeholder.com/150',
    link: 'https://example.com/link4',
  },
  {
    id: '5',
    title: 'Title 5',
    desc: 'Description 5',
    img: 'https://via.placeholder.com/150',
    link: 'https://example.com/link5',
  },
  {
    id: '6',
    title: 'Title 6',
    desc: 'Description 6',
    img: 'https://via.placeholder.com/150',
    link: 'https://example.com/link6',
  },
]
