export default [
  {
    id: '1',
    title: 'kodiakScript.js',
    desc: '슬라이드와 배너등 UI를 쉽게 제작할 수 있도록 도와주는 새로운 js 라이브러리',
    img: require('@/assets/img/test.jpg'),  
    link: '',
  },
  {
    id: '2',
    title: '웹 에디터',
    desc: '사용자가 웹 페이지를 편집할 수 있는 강력한 도구입니다.',    
    img: require('@/assets/img/test.jpg'),  
    link: '',
  },
  {
    id: '3',
    title: '웹 에디터',
    desc: '사용자가 웹 페이지를 편집할 수 있는 강력한 도구입니다.',    
    img: require('@/assets/img/test.jpg'),  
    link: '',
  },
  {
    id: '4',
    title: '웹 에디터',
    desc: '사용자가 웹 페이지를 편집할 수 있는 강력한 도구입니다.',    
    img: require('@/assets/img/test.jpg'),  
    link: '',
  },
];
