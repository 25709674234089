// src/data/menuData.js

// 메뉴 데이터 배열을 export해서 다른 파일에서 사용할 수 있게 함
export const menuItems = [
  {
    name: '웹 에디터', // 메뉴 이름
    link: '#', // 메뉴 링크
    subMenu1: [ // 첫 번째 서브메뉴 리스트
      { name: 'WEB 코딩 에디터', link: '#' },
      { name: 'HTML', link: '/' },
      { name: 'CSS', link: '/' },
      { name: 'JAVASCRIPT', link: '/kiss' }
    ],
    subMenu2: [ // 두 번째 서브메뉴 리스트
      { name: 'Hoi4 모딩 에디터', link: '#' },
      { name: '디시전', link: '/tight-hug' },
      { name: '국민정신', link: '/massage' },
      { name: '나라만들기', link: '/massage' },
      { name: '더보기', link: '/more' }
    ],
    subMenu3: [ // 두 번째 서브메뉴 리스트
      { name: 'Coming Soon', link: '' },
    ]
  },
  {
    name: '스터디 센터', // 메뉴 이름
    link: '/', // 메뉴 링크
    subMenu1: [ // 첫 번째 서브메뉴 리스트
      { name: 'WEB 코딩', link: '#' },
      { name: 'HTML', link: '/jump' },
      { name: 'CSS', link: '/kiss' },
      { name: 'JAVASCRIPT', link: '/kiss' }
    ],
    subMenu2: [ // 두 번째 서브메뉴 리스트
      { name: 'Coming Soon', link: '#' },
    ]
  },
  {
    name: '다운로드', // 메뉴 이름
    link: '#', // 메뉴 링크
    subMenu1: [ // 첫 번째 서브메뉴 리스트
      { name: 'WEB 코딩 에디터', link: '#' },
      { name: 'HTML', link: '/' },
      { name: 'CSS', link: '/' },
      { name: 'JAVASCRIPT', link: '/' }
    ],
    subMenu2: [ // 두 번째 서브메뉴 리스트
      { name: 'Hoi4 모딩 에디터', link: '#' },
      { name: '디시전', link: '/' },
      { name: '국민정신', link: '/' },
      { name: '나라만들기', link: '/' }
    ],
    subMenu3: [ // 두 번째 서브메뉴 리스트
      { name: 'Coming Soon', link: '#' },
    ]
  },
  {
    name: '사용가이드', // 메뉴 이름
    link: '/info', // 메뉴 링크
    subMenu1: [ // 첫 번째 서브메뉴 리스트
      { name: 'WEB 코딩 에디터', link: '/info' },
      { name: 'HTML', link: '/' },
      { name: 'CSS', link: '/' },
      { name: 'JAVASCRIPT', link: '/' }
    ],
    subMenu2: [ // 두 번째 서브메뉴 리스트
      { name: 'Hoi4 모딩 에디터', link: '#' },
      { name: '디시전', link: '/' },
      { name: '국민정신', link: '/' },
      { name: '나라만들기', link: '/' }
    ],
    subMenu3: [ // 두 번째 서브메뉴 리스트
      { name: 'Coming Soon', link: '#' },
    ]
  }


  // 다른 메뉴 항목 추가 가능
];
