<template>
  <div>
    <div id="header-wrap">
      <header class="wrap1280">
        <div id="logo">
          <h1>
            <router-link to="/">
              <img src="../assets/img/logo.png" alt="">
            </router-link>
          </h1>
        </div>
        <nav>
          <ul class="nav-list">
            <li v-for="(menu, index) in menus" :key="index" @mouseover="menu.show = true"
              @mouseleave="menu.show = false">
              <router-link :to="menu.link">{{ menu.name }}</router-link>
              <div v-if="menu.show" class="sub-menu">
                <div class="wrap1280">
                  <ul v-for="(sub, subIndex) in getSubMenus(menu)" :key="subIndex">
                    <li v-for="(list, listIndex) in sub" :key="listIndex">
                      <router-link :to="list.link">{{ list.name }}</router-link>
                    </li>
                  </ul>
                </div>
              </div>
            </li>
            <li class="header-line"></li>
            <li>
              <router-link to="/">kodiakScript.js</router-link>
            </li>
            <li>
              <router-link to="/">포토폴리오</router-link>
            </li>
          </ul>
        </nav>
        <div id="icon">
          <div>
            <router-link to="/">
              <i class="fa fa-search" aria-hidden="true"></i>
            </router-link>
          </div>
          <div>
            <router-link to="/">
              <i class="fa fa-user-circle" aria-hidden="true"></i>
            </router-link>
          </div>
        </div>
      </header>
    </div>
    <div class="headerN" style="height: 70px;"></div>
  </div>
</template>



<script>
import { menuItems } from '../assets/data/submenu/menu.js';

export default {
  name: 'AppHeader',
  data() {
    return {
      menus: menuItems
    };
  },
  methods: {
    getSubMenus(menu) {
      return [
        menu.subMenu1,
        menu.subMenu2,
        menu.subMenu3
      ]
    }
  }
};
</script>


<style scoped>
#logo {
  float: left;
  height: 60px;
  margin-top: 5px;
  margin-right: 20px;
}

#logo img {
  height: 60px;
}

nav>ul>li>a {
  color: #EEEEEE;
  float: left;
  display: block;
  height: 70px;
  line-height: 70px;
  padding: 0px 28px;
  text-align: center;
}

nav>ul>li>a:hover {
  background: #EEEEEE;
  color: #222222;
}

#header-wrap {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 70px;
  background-color: #222222;
  z-index: 1000;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
}

.header-line {
  margin: 15px 10px;
  width: 1px;
  height: 40px;
  background-color: #555555;
  float: left;
}

#icon {
  float: right;
}

#icon div {
  float: left;
  width: 70px;
  text-align: center;
  height: 70px;
}

i {
  font-size: 28px;
  color: #EEEEEE;
  line-height: 70px;
}

#icon div:hover {
  background: #EEEEEE;
}

#icon div:hover i {
  color: #222222;
}

.sub-menu {
  position: absolute;
  top: 70px;
  height: 280px;
  padding-top: 20px;
  left: 0px;
  background: #EEEEEE;
  width: 100%;
}

.sub-menu ul {
  width: 250px;
  float: left;
}

.sub-menu ul li {
  width: 250px;
  text-align: center;
  height: 50px;
  line-height: 50px;
  display: block;
  font-size: 16px;
  font-weight: 800;
}

.sub-menu ul li a {
  width: 250px;
  display: block;
  float: none;
  font-size: 16px;
  font-weight: 500;
  color: #555555;
}

.sub-menu ul li a:hover {
  width: 250px;
  display: block;
  float: none;
  font-weight: 600;
  color: #222222;
}
</style>
