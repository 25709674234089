<template>
  <div>
    <div class="menu-container">

      <transition-group name="fade" tag="div">
        <div v-for="(item, index) in slide" :key="index" class="slide-list"
          :style="{ zIndex: slideNow === index ? 1 : 0, opacity: slideNow === index ? 1 : 0 }">
          <div class="image-container" :style="{ backgroundImage: `url(${item.img})` }">
            <div class="wrap1280 text-box">
              <h3>{{ item.title }}</h3>
              <p>{{ item.desc }}</p>
              <a :href="item.link">More Info</a>
            </div>
          </div>
      <button @click="beforeSlide()" class="before-slide next-slide">이전</button>
      <button @click="afterSlide()" class="after-slide next-slide">다음</button>
        </div>
      </transition-group>
    </div>
    <div style="height: 600px; width: 100vw;"></div>
    <div class="slide-button" >
      <button v-for="(item, index) in slide" :key="index" @click="nextMoveSilde(index)"
          :style="{ zIndex: slideNow === index ? 1 : 0, opacity: slideNow === index ? 1 : 0 }">
        {{ item.title }}
      </button>
    </div>
    <div class="scroller-div">
       <ScrollerDiv :content="contentOne"/>
    </div>
  </div>
</template>

<script>
import menuItems from '../assets/data/main/slide.js';
import contentOne from '../assets/data/main/content1.js';
import ScrollerDiv from '@/components/ScrollerDiv.vue';

export default {
  name: 'HomeV',
  data() {
    return {
      slideNow: 0,
      slide: menuItems,
      contentOne: contentOne,
    }
  },
  methods: {
    afterSlide() {
      this.slideNow = (this.slideNow + 1) % this.slide.length;
    },
    beforeSlide() {
      this.slideNow = (this.slideNow - 1 + this.slide.length) % this.slide.length;
    },
    nextMoveSilde(index) {
      this.slideNow = index;
    },
  },
  components:{
    ScrollerDiv,
  }
}
//z-index를 바꿔주는 함수 작성해야 함
</script>

<style scoped>
.scroller-div{
  margin: 60px 0;
  padding-top:20px;
  padding-bottom:20px;
}

.menu-container {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.slide-list {
  height: 600px;
  background: #eee;
  left: 0px;
  width: 100vw;
  position: absolute;
  display: flex;
  transition: opacity 1s ease-in-out;
}

.slide-list::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* 검은색 반투명 오버레이 */
}

.text-box {
  padding: 160px;
}


.text-box h3,
.text-box p,
.text-box a {
  color: white;
  position: relative;
  z-index: 1;
}

.text-box h3 {
  font-size: 42px;
  font-weight: bold;
}

.text-box p {
  font-size: 20px;
  padding: 18px 0px;
  margin-bottom: 20px;
}

.text-box a {
  font-size: 18px;
  width: 140px;
  line-height: 34px;
  height: 34px;
  border: 1px solid white;
  text-align: center;
  border-radius: 10px;
  margin: 0px auto;
  display: block;
  bottom: -180px;
}
.slide-button{
  height:0px;
  background-color: #333;
}
.slide-button button{
  border: none;
  background-color: #333;
  color: white;
  font-size: 0px;
  width:25%;
  height:10px;
  position: relative;
  top:-18px;
  z-index: 10;
}

.next-slide{
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}
.after-slide{
  right:10%;
}

.before-slide{
  left:10%;
}


.image-container {
  width: 100%;
  background-repeat: no-repeat;
  height: 100%;
  background-size: cover;
  background-position: center;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

</style>
