<template>
  <div>
    <div class="wrap1280">
      <h3>사용가이드</h3>
      <p>사용가이드 작성하세요</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InfoPage'
};
</script>
