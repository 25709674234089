<template>
  <div id="app">
    <AppHeader />
    <router-view></router-view>
    <AppFooter/>
  </div>
</template>

<script>
import AppHeader from './components/Header.vue';
import AppFooter from './components/Footer.vue';

export default {
  name: 'App',
  components: {
    AppHeader,
    AppFooter
  }
};
</script>

<style>
/* Global styles can go here */
body{
  overflow-x: hidden;
}

.clearfix{
  clear: both;
  content: "";
  display: block;
}
*{
  font-family: "Gothic A1", sans-serif !important;
  font-style: normal;
}
.fa {
  font-family: 'FontAwesome' !important;
  font-style: normal;
  font-weight: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
}
.wrap1280{
  max-width: 1280px;
  margin: 0 auto;
}
a{text-decoration: none;}


/* 스크롤바의 기본 트랙 스타일 */
body::-webkit-scrollbar {
  width: 12px; /* 세로 스크롤바의 너비 */
  height: 12px; /* 가로 스크롤바의 높이 */
}

/* 스크롤바의 트랙 (배경) 스타일 */
body::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

/* 스크롤바의 핸들(드래그 가능한 부분) 스타일 */
body::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

/* 스크롤바 핸들을 호버할 때 스타일 */
body::-webkit-scrollbar-thumb:hover {
  background: #555;
}
</style>

