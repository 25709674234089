<template>
  <footer>
    <p>© 2024 Bear Script All rights reserved.</p>
  </footer>
</template>

<script>
export default {
  name: 'AppFooter'
};
</script>

<style scoped>
footer {
  text-align: center;
  padding: 20px;
  background-color: #f8f9fa;
  border-top: 1px solid #e9ecef;
}

footer p {
  margin: 0;
  color: #6c757d;
}
</style>
