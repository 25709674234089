<template>
  <!-- 가로 스크롤을 위한 컨테이너 wheel은 스크롤 하면 발동함-->
  <div class="scroll-container wrap1280" @wheel="scrollTrans">
    <div class="scroll-item" v-for="(item, index) in content" :key="index" :style="{backgroundImage:`url(${item.img})`}">
      <h3>{{ item.title }}</h3>
      <p>{{ item.desc }}</p>
      <a :href="item.link">더 보기</a>
    </div>
  </div>
</template>

<script>

export default {
  name: "ScrollableDivs",
  props: {
    content: Array,
  },
  methods: {
    // 마우스 휠 이벤트를 처리하는 함수
    scrollTrans(e) {
      e.preventDefault();// 기본 세로 스크롤을 막아줌

      const divScroll = e.currentTarget; // 이벤트 타겟을 가져옴

      // 세로 스크롤 이벤트를 가로 스크롤로 변경
      if (e.deltaY > 0) {
        divScroll.scrollLeft += 30; // 스크롤을 오른쪽으로 30픽셀 이동
      } else {
        divScroll.scrollLeft -= 30; // 스크롤을 왼쪽으로 30픽셀 이동
      }
    },
  }
};
</script>

<style scoped>
.scroll-container {
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  height: 340px;
  width: 100vw;
  white-space: nowrap;
}

.scroll-item {
  min-width: 526px;
  height: 320px;
  border: 1px solid #ccc;
  display: flex;
  background-color: #D9D9D9;
  align-items: center;
  margin-left: 24px;
  justify-content: center;
}

.scroll-item:first-child {
  margin-left: 0px;
}

/* 스크롤바의 기본 트랙 스타일 */
.scroll-container::-webkit-scrollbar {
  height: 12px;
  /* 가로 스크롤바의 높이 */
}

/* 스크롤바의 트랙 (배경) 스타일 */
.scroll-container::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

/* 스크롤바의 핸들(드래그 가능한 부분) 스타일 */
.scroll-container::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

/* 스크롤바 핸들을 호버할 때 스타일 */
.scroll-container::-webkit-scrollbar-thumb:hover {
  background: #555;
}
</style>
